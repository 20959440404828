import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertController, LoadingController, NavController, Platform } from '@ionic/angular';
import { DataShopsService } from '../../shared-utilities/services/data-shops/data-shops.service';
import { CanDeactivateGuard } from '../../main-view-guards/can-deactivate/can-deactivate.guard';
import { HttpStatusCode } from '../../../constans/http-status-code.enum';
import { INotAcceptableErrorDto } from './not-acceptable.interceptor.dto';
import { IAlertDetailsData } from './not-acceptable.interceptor.models';

@Injectable()
export class NotAcceptableInterceptor implements HttpInterceptor {

    // Interceptor data
    private isNotAcceptableAlertVisible: boolean;

    constructor(
        private readonly alertController: AlertController,
        private readonly dataShopsService: DataShopsService,
        private readonly loadingController: LoadingController,
        private readonly navController: NavController,
        private readonly platform: Platform
    ) {
    }

    /**
     * Used to catch all not acceptable app response
     * @param req Request object
     * @param next Next handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req.clone()).pipe(catchError((error: HttpErrorResponse) => {

            // None acceptable error
            if (error.status !== HttpStatusCode.NOT_ACCEPTABLE) {
                return throwError(error);
            }

            // Handle unauthorised
            return this.handleNotAcceptableError(this.extractErrorDetails(error.error));

        }));

    }

    get isDesktopAgent() {
        return this.platform.is('desktop') || this.platform.is('mobileweb');
    }

    /**
     * Parses given data to not acceptable error details
     * @param data Data to parse
     */
    private extractErrorDetails(data: any): INotAcceptableErrorDto {

        return {
            response_code: HttpStatusCode.NOT_ACCEPTABLE,
            code: data instanceof Object ? data['code'] : undefined,
            message: data instanceof Object ? data['message'] : undefined,
        };

    }

    /**
     * Handles not acceptable errors
     * @param details Error details
     */
    private async handleNotAcceptableError(details: INotAcceptableErrorDto): Promise<null> {

        // Alert is already visible
        if (this.isNotAcceptableAlertVisible) {
            return null;
        }

        // Set is visible to true
        this.isNotAcceptableAlertVisible = true;

        // Close all current alerts and loadings
        await Promise.all([
            this.loadingController.dismiss().catch(() => null),
            this.alertController.dismiss().catch(() => null)
        ]);

        // Getting current shop id
        const currentShopId: number = this.dataShopsService.getShopIdFromUrl();

        // If version control error
        if (details.code === 'not_supported_version') { // clear serverOrigin and authentication token from all shops
            await this.dataShopsService.signOutFromAllShops(true).toPromise();
        } else { // sign out only from current shop
            await this.dataShopsService.signOutFromShop(currentShopId).toPromise();
        }

        // Navigate to sign in and create alert
        const navigateRoot = currentShopId !== null
            ? `/sign-in-view/sign-in/${currentShopId}?${CanDeactivateGuard.PARAM_FORCE_DEACTIVATION}=1`
            : this.isDesktopAgent ? `/sign-in-view/add-shop?${CanDeactivateGuard.PARAM_FORCE_DEACTIVATION}=1` : `/sign-in-view/shop-list?${CanDeactivateGuard.PARAM_FORCE_DEACTIVATION}=1`;
        const [, alert] = await Promise.all([
            this.navController.navigateRoot(navigateRoot),
            this.alertController.create(Object.assign(this.getAlertDetailsData(details), {
                backdropDismiss: false,
                buttons: ['OK']
            })),
        ]);

        // On alert close
        alert.onWillDismiss().then(() => this.isNotAcceptableAlertVisible = false);

        // Show alert
        await alert.present();

        // Return
        return null;

    }

    /**
     * Gets header and message depending on error type
     * @param error Error object
     */
    private getAlertDetailsData(error: INotAcceptableErrorDto): IAlertDetailsData {

        // Depending on error type
        if (error.code === 'not_supported_version') { // not supported version
            return {
                header: 'Nieaktualna wersja',
                message: error.message ? error.message : 'Wykryto nieaktualną wersję aplikacji.<br/>Zaktualizuj i spróbuj ponownie.'
            };
        } else { // other error
            return {
                header: 'Niepowodzenie',
                message: error.message ? error.message : 'Wystąpił błąd połączenia.<br/>Spróbuj ponownie później.'
            };
        }

    }

}
