import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DataShopsService } from '../../shared-utilities/services/data-shops/data-shops.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    constructor(
        private readonly dataShopsService: DataShopsService,
    ) {
    }

    /**
     * Used to add authentication headers to request
     * @param req Request object
     * @param next Next handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.params.get('function') === 'mobileLogout') {
            const domain = req.url.split('/api')[0];
            return this.dataShopsService.getShopFromDomain(domain).pipe(
                map(shopData => shopData && shopData.authenticationToken
                    ? req.clone({ // replace the original headers with cloned headers, updated with the authorization
                        setHeaders: {
                            'x-access-token': shopData.authenticationToken,
                        }
                    })
                    : req.clone()
                ),
                switchMap(authenticatedRequest => next.handle(authenticatedRequest)) // send cloned request with header to the next handler.
            );
        }

        return this.dataShopsService.getShopFromUrl().pipe( // get the auth token from the service.
            map(shopData => shopData && shopData.authenticationToken
                ? req.clone({ // replace the original headers with cloned headers, updated with the authorization
                    setHeaders: {
                        'x-access-token': shopData.authenticationToken,
                    }
                })
                : req.clone()
            ),
            switchMap(authenticatedRequest => next.handle(authenticatedRequest)) // send cloned request with header to the next handler.
        );

    }

}
