import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CanActivateGuard} from './main-view-guards/can-activate/can-activate.guard';

const routes: Routes = [
    {path: '', redirectTo: 'sign-in-view', pathMatch: 'full'},
    {
        path: 'main-view/:id',
        loadChildren: './main-view/main-view.module#MainViewPageModule',
        canActivate: [CanActivateGuard],
    },
    {path: 'sign-in-view', loadChildren: './sign-in-view/sign-in-view.module#SignInViewPageModule'},
    {path: '**', redirectTo: 'sign-in-view'},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
