import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouteReuseStrategy} from '@angular/router';
import {IonicStorageModule} from '@ionic/storage';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthenticationInterceptor} from './interceptor-services/authentication/authentication.interceptor';
import {UnauthorisedInterceptor} from './interceptor-services/unauthorised/unauthorised.interceptor';
import {ServerOriginInterceptor} from './interceptor-services/server-origin/server-origin.interceptor';
import {CorsInterceptor} from './interceptor-services/cors/cors.interceptor';
import {appRoutingAnimation} from './app-routing.animation';
import {CacheInterceptor} from './interceptor-services/cache/cache.interceptor';
import {OfflineInterceptor} from './interceptor-services/offline/offline.interceptor';
import {Network} from '@ionic-native/network/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {NotAcceptableInterceptor} from './interceptor-services/not-acceptable/not-acceptable.interceptor';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {UnknownErrorInterceptor} from './interceptor-services/unknown-error/unknown-error.interceptor';
import {VersionInterceptor} from './interceptor-services/version/version.interceptor';
import {AppMinimize} from '@ionic-native/app-minimize/ngx';
import {MobileAccessibility} from '@ionic-native/mobile-accessibility/ngx';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios',
            swipeBackEnabled: false,
            loadingSpinner: 'bubbles',
            navAnimation: appRoutingAnimation,
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        Network,
        InAppBrowser,
        AppVersion,
        AppMinimize,
        MobileAccessibility,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        [
            {provide: HTTP_INTERCEPTORS, useClass: UnknownErrorInterceptor, multi: true},
            {provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true},
            {provide: HTTP_INTERCEPTORS, useClass: CorsInterceptor, multi: true},
            {provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true},
            {provide: HTTP_INTERCEPTORS, useClass: ServerOriginInterceptor, multi: true},
            {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
            {provide: HTTP_INTERCEPTORS, useClass: UnauthorisedInterceptor, multi: true},
            {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
            {provide: HTTP_INTERCEPTORS, useClass: NotAcceptableInterceptor, multi: true},
        ]
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
